@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@1,300&display=swap');

.App {
  font-family: 'Roboto Condensed', sans-serif;
  background-image: url(./images/background-carwash2.jpg);
  background-size: cover;
  background-color: #9CAEA9;
  background-attachment: fixed;
}
.main-container{
  text-align: center;
}

.footer-fill{
  min-height: calc(100vh - 240px);
}

.bg-tpq{
  background-color: rgb(16, 114, 160, 0.8) !important;
}

a:-webkit-any-link {
  text-decoration: none;
}
.color-green{
  color: rgb(86, 250, 21);
}
.color-red{
  color: rgb(255, 234, 0);
}

.logo{
  border-radius: 50%;
}